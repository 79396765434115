import { AVAILABLE_LANGUAGES, AVAILABLE_LOCALES } from 'constants/settings';
import { array, bool, number, string } from 'prop-types';
import { createContext, useEffect } from 'react';

import { isDefined } from 'services/util/auxiliaryUtils';
import useCookies from 'hooks/cookies';
import { getCompanyProfileIds } from 'services/user';
import { useUserStore } from 'store/user';
import { AVAILABLE_USER_LOCALES } from 'constants/user';

const OAUserContext = createContext({
    userLanguage: string | null,
    companyProfileId: number | null,
    locale: string,
    userLocale: string,
    salesSegmentsList: array,
    hasUniqueSalesSegment: bool,
    isB2C: bool,
});

const OAUserProvider = ({ children }) => {
    const { getCookie } = useCookies();
    const { locale, switchLanguage } = useUserStore();
    const defaultLocale = AVAILABLE_LANGUAGES.find((lng) => lng.icon === AVAILABLE_LOCALES.EN);

    useEffect(() => {
        if (!isDefined(locale?.isoLocale)) {
            switchLanguage(defaultLocale);
        }
    }, []); // eslint-disable-line

    return (
        <OAUserContext.Provider
            value={{
                userLanguage: 'gb',
                userLocale: AVAILABLE_USER_LOCALES.enGB,
                locale,
                companyProfileId: getCompanyProfileIds().PPC,
                salesSegmentsList: [],
                hasUniqueSalesSegment: true,
                isB2C: localStorage.getItem('salesSegment') === 'B2C',
                personalizedAdvertisingCookie: getCookie('PersonalizedAdvertisingCookie'),
            }}
        >
            {children}
        </OAUserContext.Provider>
    );
};

export { OAUserContext, OAUserProvider };
