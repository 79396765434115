import { isEnvDevelopment } from './settings';
import { getCompanyProfileIds } from './user';
import { isDefined } from './util/auxiliaryUtils';

export function getGeoCodeInfo(geoCodeAllData, geoCodeAllDataEnGB, companyProfileId, options) {
    const marker = options?.marker ?? null;
    const evt = options?.evt ?? null;

    const isArrayData = Array.isArray(geoCodeAllData);
    const isArrayDataEnGB = Array.isArray(geoCodeAllDataEnGB);

    let geoCodeData = isArrayData ? geoCodeAllData[0] : geoCodeAllData;
    let geoCodeDataEnGB = isArrayDataEnGB ? geoCodeAllDataEnGB[0] : geoCodeAllDataEnGB; //only en-GB

    if (geoCodeAllData?.length === 0) geoCodeData = geoCodeAllDataEnGB?.[0] ?? null; //fallback

    let country;
    let locality;
    let county;
    let county_abrev;
    let county_en; //only en-GB
    let postal_code;
    let address;
    let address_en; //only en-GB
    let lat;
    let lng;

    //#region locality
    let findLocalitybylvl2 =
        isArrayData ?
            geoCodeAllData.find(
                (item) => item?.address_components?.find((cmp) => cmp.types.includes('administrative_area_level_2'))?.long_name
            )
        :   null;

    country = geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('country'))?.short_name ?? null;
    locality =
        findLocalitybylvl2?.address_components?.find((item) => item.types.includes('administrative_area_level_2'))?.long_name ||
        geoCodeData?.address_components?.find((cmp) => cmp.types.includes('locality') && cmp.types.includes('political'))?.long_name;
    //#endregion locality

    postal_code =
        geoCodeData?.address_components?.find((item) => item.types.includes('postal_code'))?.long_name ??
        geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('postal_code'))?.long_name ??
        //fallback down 1 level to get postal code
        geoCodeAllData?.[1]?.address_components?.find((item) => item.types.includes('postal_code'))?.long_name ??
        geoCodeAllDataEnGB?.[1]?.address_components?.find((item) => item.types.includes('postal_code'))?.long_name ??
        //fallback down 2 levels to get postal code
        geoCodeAllData?.[2]?.address_components?.find((item) => item.types.includes('postal_code'))?.long_name ??
        geoCodeAllDataEnGB?.[2]?.address_components?.find((item) => item.types.includes('postal_code'))?.long_name ??
        null;

    if (isDefined(marker)) {
        lat = marker.lat;
        lng = marker.lng;
    } else {
        lat = evt ? evt?.latLng?.lat() : geoCodeData.geometry.location?.lat;
        lng = evt ? evt?.latLng?.lng() : geoCodeData.geometry.location?.lng;
    }

    if (
        geoCodeData.address_components?.find((item) => item.types.includes('route'))?.long_name &&
        geoCodeData.address_components?.find((item) => item.types.includes('street_number'))?.long_name
    ) {
        address = `${geoCodeData.address_components?.find((item) => item.types.includes('route'))?.long_name}, ${geoCodeData.address_components?.find((item) => item.types.includes('street_number'))?.long_name}`;
    } else {
        address = geoCodeData?.formatted_address;
    }

    //only en-GB
    if (
        geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('route'))?.long_name &&
        geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('street_number'))?.long_name
    ) {
        address = `${geoCodeDataEnGB.address_components?.find((item) => item.types.includes('route'))?.long_name}, ${geoCodeDataEnGB.address_components?.find((item) => item.types.includes('street_number'))?.long_name}`;
    } else {
        address_en = geoCodeDataEnGB?.formatted_address ?? null;
    }

    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_SWISS:
        case getCompanyProfileIds().PROFILE_BASE_USA:
        case getCompanyProfileIds().ROMANDE: {
            //municipio
            county = geoCodeData?.address_components.find((item) => item.types.includes('locality'))?.long_name;
            county_en = geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('locality'))?.long_name ?? null;
            county_abrev =
                getCompanyProfileIds().PROFILE_BASE_USA ?
                    geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('administrative_area_level_2'))?.short_name ??
                    null
                :   geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('locality'))?.short_name ?? null;
            break;
        }
        case getCompanyProfileIds().PPC: {
            //municipio
            county = geoCodeData?.address_components.find((item) => item.types.includes('administrative_area_level_3'))?.long_name;
            county_en =
                geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('administrative_area_level_3'))?.long_name ?? null;
            county_abrev =
                geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('administrative_area_level_3'))?.short_name ?? null;
            break;
        }
        case getCompanyProfileIds().EVOLO:
        case getCompanyProfileIds().PROFILE_BASE_UK: {
            //municipio
            county = geoCodeData?.address_components.find((item) => item.types.includes('locality'))?.long_name;
            county_en = geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('locality'))?.long_name ?? null;
            county_abrev = geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('locality'))?.short_name ?? null;
            break;
        }

        case getCompanyProfileIds().PROFILE_BASE_IT:
        case getCompanyProfileIds().EDP_IT:
        case getCompanyProfileIds().EFZ: {
            //municipio
            let geoCodeData = geoCodeAllDataEnGB
                ?.find((item) => item?.address_components.find((cmp) => cmp.types.includes('administrative_area_level_2')))
                ?.address_components?.find((item) => item.types.includes('administrative_area_level_2'));
            county = county_en = geoCodeData?.long_name ?? null;
            county_abrev = geoCodeData?.short_name ?? null;

            break;
        }
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().NRG: {
            //municipio
            let geoCodeData = geoCodeAllDataEnGB
                ?.find((item) => item?.address_components.find((cmp) => cmp.types.includes('administrative_area_level_1')))
                ?.address_components?.find((item) => item.types.includes('administrative_area_level_1'));
            county = county_en = geoCodeData?.long_name ?? null;
            county_abrev = geoCodeData?.short_name ?? null;

            break;
        }
        case getCompanyProfileIds().PROFILE_EDP_FR:
        case getCompanyProfileIds().PROFILE_BASE_DE: {
            //municipio
            county = geoCodeData?.address_components.find((item) => item.types.includes('locality'))?.long_name;
            county_en = geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('locality'))?.long_name ?? null;
            county_abrev = geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('locality'))?.short_name ?? null;
            break;
        }
        case getCompanyProfileIds().EDP_ES: {
            //municipio
            let countyData = geoCodeData?.address_components.find((item) => item.types.includes('locality'));
            if (!countyData)
                countyData = geoCodeData?.address_components.find((item) => item.types.includes('administrative_area_level_1'));

            county = countyData?.long_name ?? null;
            county_en = countyData?.long_name ?? null;
            county_abrev = countyData?.long_name ?? null;
            // county_abrev = countyData?.short_name ?? null;//tmp disabled missing data in db
            break;
        }
        case getCompanyProfileIds().EDP_PL:
        default: {
            //municipio
            county = geoCodeData?.address_components.find((item) => item.types.includes('administrative_area_level_1'))?.long_name;
            county_en =
                geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('administrative_area_level_1'))?.long_name ?? null;
            county_abrev =
                geoCodeDataEnGB?.address_components?.find((item) => item.types.includes('administrative_area_level_1'))?.short_name ?? null;
            break;
        }
    }

    if (isEnvDevelopment()) {
        /* only TST */
        console.log('efz-> DEBUG: geoCodeData', geoCodeData);
        console.log('efz-> DEBUG: geoCodeDataEnGB', geoCodeDataEnGB);
    }

    return {
        lat: typeof lat === 'function' ? lat() : lat,
        lng: typeof lng === 'function' ? lng() : lng,
        description: address, //for input autocomplete
        address_en, //by debugger
        address,
        locality,
        city: locality, //city
        postal_code,
        county,
        county_en,
        county_abrev,
        country,
    };
}
