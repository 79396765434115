import { CompanyDescriptionB2B } from 'constants/products/solarpvSimple';
import { getCompanyProfileIds } from 'services/user';

const CompanyTitleB2B = ({ fontSize = 2, isBlack = false, hasMargin = false, productName = null }) => {
    return (
        <div className={`company-title fs-${fontSize} ${hasMargin ? 'mb-4' : ''} ${isBlack ? 'black' : ''}`}>
            {/* {CompanyDescriptionB2B?.[getCompanyProfileIds().PPC].logo} */}
            <span>
                {productName ?
                    CompanyDescriptionB2B?.[getCompanyProfileIds().PPC].shortenedTitle
                :   CompanyDescriptionB2B?.[getCompanyProfileIds().PPC].title}
                {!!productName && <span className="company-title-product">{productName}</span>}
                <sup className="ms-1">{CompanyDescriptionB2B?.[getCompanyProfileIds().PPC].superscript}</sup>
            </span>
        </div>
    );
};

export default CompanyTitleB2B;
