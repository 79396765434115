import { memo, useContext, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Bullet from './Bullet';
import Alert from 'components/@efz/Alert';
import Lottie2 from 'components/@efz/Lottie/Lottie2';
import IntlMessages from 'components/util/IntlMessages';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import useRoofIdentification from 'hooks/products/solarpvSimple/useRoofIdentification';
import { STEPS_RESERVED_AREA, spvSimpleActions, ROOF_ID_BULLETS_NUMBER } from 'constants/products/solarpvSimple';
import RobotAnimation from 'assets/lottie/PPC_Robot_House_Dark_Colors';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import { Button } from '@save2compete/efz-design-system';

const RoofIdentification = () => {
    const isMobile = useMediaQuery('(max-width:600px)', { noSsr: true });

    let { isRequestRoofIFail } = useRoofIdentification();

    const {
        spvSimpleState: {
            isDetectRoof,
            inputs: { area_type },
        },
        spvSimpleDispatchHandler: dispatch,
    } = useContext(SPVSimpleContext);

    // the first bullet is already rendered (i.e. is already being shown on mount)
    const [bulletRendering, setBulletRendering] = useState(isDetectRoof ? 2 : 3);

    return (
        <div className="roof-id-container">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2b.step.3')}`}</title>
            </Helmet>
            <Lottie2
                options={{
                    animationData: RobotAnimation,
                }}
                width={isMobile ? 300 : 650}
                // height={250}
                isPaused={isRequestRoofIFail}
            />
            <div className="roof-id-list">
                {[...Array(ROOF_ID_BULLETS_NUMBER).keys()]
                    .map((n) => n + 1)
                    .map((el) => (
                        <Bullet
                            key={el}
                            id={el}
                            conditionToShow={el === 1 ? isDetectRoof : true}
                            bulletRendering={bulletRendering}
                            setBulletRendering={setBulletRendering}
                            isRequestRoofIFail={isRequestRoofIFail}
                            area_type={area_type}
                        />
                    ))}
                {isRequestRoofIFail ?
                    <div className="d-flex align-items-center flex-column">
                        <Alert severity="error" className="w-100">
                            <IntlMessages
                                id="page.spvSimple.roofId.error.alert"
                                values={{
                                    value: (
                                        <span
                                            className="link"
                                            onClick={() => dispatch(spvSimpleActions.SET_STEP, STEPS_RESERVED_AREA.HOUSING_TYPE)}
                                        >
                                            <IntlMessages id="label.step" /> {STEPS_RESERVED_AREA.HOUSING_TYPE}
                                        </span>
                                    ),
                                }}
                            />
                        </Alert>
                        <Button
                            className="mt-4"
                            onClick={() => dispatch(spvSimpleActions.SET_STEP, STEPS_RESERVED_AREA.ADDRESS)}
                            dataTestId="new-address"
                        >
                            <IntlMessages id="page.spvSimple.roofId.newAddress.button" />
                        </Button>
                        <Button className="my-4" onClick={() => dispatch(spvSimpleActions.DRAW_ROOF_MANUALLY, true)} dataTestId="draw-roof">
                            <IntlMessages id="page.spvSimple.roofId.drawRoof.button" />
                        </Button>
                    </div>
                :   <></>}
            </div>
        </div>
    );
};

export default memo(RoofIdentification);
