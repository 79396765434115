import { CompanyDescriptionB2C } from 'constants/products/solarpvSimple';
import { getCompanyProfileIds } from 'services/user';

const CompanyTitleB2C = ({ fontSize = 2, isBlack = false, hasMargin = false }) => {
    return (
        <div className={`company-title fs-${fontSize} ${hasMargin ? 'mb-4' : ''} ${isBlack ? 'black' : ''}`}>
            {/* {CompanyDescriptionB2C?.[getCompanyProfileIds().PPC]?.logo} */}
            <span>{CompanyDescriptionB2C?.[getCompanyProfileIds().PPC]?.title}</span>
        </div>
    );
};

export default CompanyTitleB2C;
